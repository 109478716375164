/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '../components/layout';
import ProfileBuilder from '../components/registration'
import MetaData from '../components/metadata'

const EditProfile = (props) => {

  return (
    <Layout {...props} plain>
      <MetaData title="Edit Profile" />
      <ProfileBuilder
        verifyPassword
        favouritePlayer
        favouriteKit
        shirtNumber
        editMode
      />
    </Layout>
  )
}

export default EditProfile